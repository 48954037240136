<template>
    <v-dialog v-model="dialog" scrollable max-width="1000px">
        <v-card tile class="grey darken-3">
            <v-app-bar dense color="blue-grey darken-2">
                <v-toolbar-title>Auswahl</v-toolbar-title>
                <!-- <v-spacer></v-spacer> -->
                <v-text-field flat solo dense hide-details label="Suche" prepend-inner-icon="mdi-magnify" background-color="blue-grey darken-3"
                    v-model="search" clearable class="shrink mx-4"
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn icon>
                    <v-icon>mdi-reload</v-icon>
                </v-btn>
            </v-app-bar>
            <v-card-text class="ma-0 pa-0" style="height: 520px;">
   <v-simple-table dense fixed-header height="300px" class="grey darken-3">
      <template v-slot:default class="grey darken-3">
        <thead>
          <tr>
            <th class="text-left grey darken-3">Name</th>
            <th class="text-left grey darken-3">Calories</th>
            <th class="text-left grey darken-3">Name</th>
            <th class="text-left grey darken-3">Calories</th>
            <th class="text-left grey darken-3">Name</th>
            <th class="text-left grey darken-3">Calories</th>
            <th class="text-left grey darken-3">Name</th>
            <th class="text-left grey darken-3">Calories</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in desserts" :key="item.name" class="ma-0 pa-0 px-0">
            <td class="ma-0 pa-0 pt-1 pl-1" width="200px">
              <v-text-field v-model="item.name" label="Solo" hide-details dense placeholder="Placeholder" solo
               class="ma-0 pa-0" background-color="grey darken-2" height="20" style="font-size: 10pt;"></v-text-field>
            </td>
            <td class="ma-0 pa-0 pt-1 pl-1" width="150px">
              <v-text-field v-model="item.name" label="Solo" hide-details dense placeholder="Placeholder" solo
               class="ma-0 pa-0" background-color="grey darken-2" height="20" style="font-size: 10pt;"></v-text-field>
            </td>
            <td class="ma-0 pa-0 pt-1 pl-1" width="100px">
              <v-text-field v-model="item.name" label="Solo" hide-details dense placeholder="Placeholder" solo
               class="ma-0 pa-0" background-color="grey darken-2" height="20" style="font-size: 10pt;"></v-text-field>
            </td>
            <td class="ma-0 pa-0 pt-1 pl-1" width="30px">
              <v-text-field v-model="item.name" label="Solo" hide-details dense placeholder="Placeholder" solo
               class="ma-0 pa-0" background-color="grey darken-2" height="20" style="font-size: 10pt;"></v-text-field>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
            </v-card-text>
            <v-divider />
            <v-card-actions>
            <v-btn text class="warning" @click="onCancel()">Abbrechen</v-btn>
            <v-spacer />
            <v-btn v-if="selectedItem != undefined" text class="success" @click="onSubmit()">Übernehmen</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
	export default {
	  	name: 'DialogThree',
		components: {},
	  	props: {
	  	},
		data: () => ({
            loading: false,
            dialog: false,
            search: null,
            selectedItem: undefined,
            items: [],
            desserts: [
                {
                    name: "Frozen Yogurt",
                    calories: 159
                },
                {
                    name: "Ice cream sandwich",
                    calories: 237
                },
                {
                    name: "Eclair",
                    calories: 262
                },
                {
                    name: "Cupcake",
                    calories: 305
                },
                {
                    name: "Gingerbread",
                    calories: 356
                },
                {
                    name: "Jelly bean",
                    calories: 375
                },
                {
                    name: "Lollipop",
                    calories: 392
                },
                {
                    name: "Honeycomb",
                    calories: 408
                },
                {
                    name: "Donut",
                    calories: 452
                },
                {
                    name: "KitKat",
                    calories: 518
                }
            ],
        }),
        computed: {
            filteredItems () {
                return this.items.filter(item => {
                    if(!this.search) return this.items
                    return (item.title.toLowerCase().includes(this.search.toLowerCase()))
                })
            },
        },
		methods: {
            onCancel() {
                this.$emit('cancel')
                this.dialog = false
            },
            onSubmit() {
                console.log('selected:', this.selectedItem)
                this.$emit('submit', this.filteredItems[this.selectedItem])
                this.dialog = false
            },
            selectionChange(e) {
                console.log('selection changed:', e)
            },
        },
		mounted() {
            console.log('dialog three mounted')
            this.dialog = true
        },
        beforeDestroy() {
            console.log('destroying dialog three')
        },
	  	watch: {

        },
    }
</script>
<style scoped>
.v-data-table td {
  border: none !important;
}

.v-data-table >>> tbody tr:hover {
  background: transparent !important;
}

</style>